import translate from 'src/locales/translate'
import { usePageContext } from 'vike-react/usePageContext'

function T({ children, args }) {
    const pageContext = usePageContext()
    const { locale } = pageContext

    const textLocalized = translate(children, locale, args)
    return textLocalized
}

export default T;
